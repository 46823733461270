$theme-colors: (
  "primary": #a20d5e,
  "secondary": #609f77,
  "grey-base": #4d5257
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";

body {
  //   margin-top: 86px !important;
  background: #fff;
}

.card {
  height: 100%;
}

.pad-btm {
  height: 300px;
}

.footer {
  font-family: "Poppins", "Roboto", Helvetica, Arial, sans-serif;
  position: fixed;
  bottom: 0;
  width: 100vw;
  line-height: 60px;
  color: #4d5257;

  .social-buttons {
    svg {
      margin-right: 10px;
      margin-top: 15px;
    }
  }
}
